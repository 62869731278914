import racqy_images from '../utils/images';
import padelRacquet from '../../src/assets/icons/Padel.svg';

const sports = {
  tennis: 'Tennis',
  padel: 'Padel',
  squash: 'Squash',
  badminton: 'Badminton',
  football: 'Soccer',
  golf: 'Golf',
  basketball: 'Basketball',
  beach_tennis: 'Beach tennis',
  skateboarding: 'Skateboarding',
  scooter: 'Scooter',
  surfing: 'Surfing',
  gym: 'Gym',
  running: 'Running',
  cycling: 'Cycling',
  table_tennis: 'Table tennis',
  cricket: 'Cricket',
  pickelball: 'Pickelball',
  swimming: 'Swimming',
  baseball: 'Baseball',
  american_football: 'American football',
  skiing: 'Skiing',
  snowboarding: 'Snowboarding',
  climbing: 'Climbing',
  bmx: 'BMX',
  ballet: 'Ballet',
  yoga: 'Yoga',
  boxing: 'Boxing',
  mma: 'MMA',
  karate: 'Karate',
  parkour: 'Parkour',
  dance: 'Dancing',
  gymnastics: 'Gymnastics',
  trampolining: 'Trampolining',
  'pole vaulting': 'Pole vaulting',
  archery: 'Archery',
  dodgeball: 'Dodgeball',
  frisbee: 'Frisbee',
  figure_skating: 'Figure skating',
  ice_hockey: 'Ice hockey',
  field_hockey: 'Field hockey',
  wrestling: 'Wrestling',
  judo: 'Judo',
  muay_thai: 'Muay thai',
  taekwondo: 'Taekwondo',
  kart_racing: 'Kart racing',
  speed_skating: 'Speed skating',
  kite_surfing: 'Kite surfing',
  sailing: 'Sailing',
  canoeing: 'Canoeing',
  rowing: 'Rowing',
  water_polo: 'Water polo',
  diving: 'Diving',
  motorsports: 'Motorsports',
  custom: 'Custom',
  underwater_diving: 'Underwater diving',
  volleyball: 'Volleyball',
  horse_riding: 'Horse riding',
  futsal: 'Futsal',
  footvolley: 'Footvolley',
  fishing: 'Fishing',
  dancing: 'Dancing',
  beach_soccer: 'Beach soccer',
};
const calculateDistance = (loc1, loc2, roundToKM = true) => {
  if (!loc1 || !loc1.lat || !loc1.lng || !loc2 || !loc2.lat || !loc2.lng) {
    return '';
  }

  var lat1 = Deg2Rad(loc1.lat);
  var lat2 = Deg2Rad(loc2.lat);
  var lon1 = Deg2Rad(loc1.lng);
  var lon2 = Deg2Rad(loc2.lng);
  var latDiff = lat2 - lat1;
  var lonDiff = lon2 - lon1;
  var R = 6371000; // metres
  var p1 = lat1;
  var p2 = lat2;
  var d_lat = latDiff;
  var d_lon = lonDiff;

  var a =
    Math.sin(d_lat / 2) * Math.sin(d_lat / 2) +
    Math.cos(p1) * Math.cos(p2) * Math.sin(d_lon / 2) * Math.sin(d_lon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  var d = R * c;

  var dist =
    Math.acos(
      Math.sin(p1) * Math.sin(p2) +
        Math.cos(p1) * Math.cos(p2) * Math.cos(d_lon)
    ) * R;

  if (roundToKM) {
    return Math.round(dist / 1000);
  }

  return dist;
};

const calculateDifference = (difference) => {
  if (difference === 0) {
    return '+0,0';
  } else if (
    (difference < 0 || difference > 0) &&
    Number.isInteger(difference)
  ) {
    let diff = difference / 10;
    diff = String(diff).replace('.', ', ');

    return diff;
  } else if (!Number.isInteger(difference)) {
    let diff = difference?.toFixed(1);
    diff = String(diff).replace('.', ', ');

    if (Number(diff.replace(', ', '.')) > 0) {
      return `+${diff}`;
    } else if (Number(diff.replace(', ', '.')) < 0) {
      return `-${diff}`;
    }
  }
};

function getSportHeaderImage(sport) {
  let image = racqy_images.sport_backgrounds[sport];

  return image;
}
const getSportIcon = (sport, svg) => {
  let icon = padelRacquet;

  icon = svg
    ? racqy_images.sport_icons[sport]
    : racqy_images.sport_icons_png[sport];

  return icon;
};

const RACQY_MEMBER_STATUS_TYPES = {
  DEFAULT: null,
  MEMBER: 'MEMBER',
  REQUESTED: 'REQUESTED',
  NOT_MEMBER: 'NOT_MEMBER',
  INVITED: 'INVITED',
};
function getLevelColor(level) {
  if (level >= 1 && level < 2) {
    return {
      bg: '#A8A2C7',
      main: 'black', //'#6C3E8E'
    };
  } else if (level >= 2 && level < 3) {
    return {
      bg: '#BAD6F0',
      main: 'black', //'#258F95'
    };
  } else if (level >= 3 && level < 4) {
    return {
      bg: '#A0BAD4',
      main: 'black', //'#406F58'
    };
  } else if (level >= 4 && level < 5) {
    return {
      bg: '#BBE2D3',
      main: 'black', //'#6AB928'
    };
  } else if (level >= 5 && level < 6) {
    return {
      bg: '#FDED9E',
      main: 'black', //'#D0D008'
    };
  } else if (level >= 6 && level < 7) {
    return {
      bg: '#F6D9A1',
      main: 'black', //'#C97D36'
    };
  } else if (level >= 7) {
    return {
      bg: '#FABFB6',
      main: 'black', //'#F04933'
    };
  }

  return {
    bg: '#D9D7F8',
    main: 'white', //'#6C3E8E'
  };
}

function getSportsName(sport) {
  return sports[sport];
}
function Deg2Rad(deg) {
  return (deg * Math.PI) / 180;
}
export {
  getSportHeaderImage,
  getLevelColor,
  RACQY_MEMBER_STATUS_TYPES,
  getSportIcon,
  getSportsName,
  calculateDistance,
  calculateDifference,
};
