import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import ActionButton from '../components/ActionButton';
import ActivityCard from '../components/ActivityCard';
import CategoriesBox from '../components/CategoriesBox';
import Layout from '../components/Layout';
import locationBlue from '../images/icons/location-blue.svg';
import { racqys } from '../api/api-requests';
import { client } from '../api/racqy';
import GetLocation from '../hooks/getLocation';
import { SUPPORTED_SPORTS } from '../utils/racqy';
import MobileCategoriesBox from '../components/MobileCategoriesBox';
import Spinner from '../components/Spinner';
import racqyIcon from '../assets/icons/racqy-icon.svg';
import arrowIcon from '../assets/icons/arrow-right-icon.svg';
import { hideLoader, showLoader } from '../utils/domSelector';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
);

function AutocompleteField({
  gpsLocation,
  autocompleteState,
  setAutocompleteState,
  onItemClick,
  sport,
  query,
  setQuery,
  close,
  setAutoCompleteDropDown,
}) {
  // (1) Create a React state.
  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // Extract the input value from the Autocomplete state
          const { query } = state;

          // Update your React state with the input value

          setQuery(query.name);

          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state);
          setAutoCompleteDropDown(false);
        },

        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: 'locations',
              getItemInputValue({ item }) {
                return item; // Pass the input value from your state
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'Locations',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<span>',
                        highlightPostTag: '</span>',
                        getRankingInfo: true,
                        aroundLatLngViaIP: true,
                        filters: sport ? `sports:${sport}` : '',
                        aroundRadius: 'all',
                      },
                    },
                    {
                      indexName: 'Users',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<span>',
                        highlightPostTag: '</span>',
                        getRankingInfo: true,
                        aroundLatLngViaIP: true,
                        filters: sport ? `sports:${sport}` : '',
                        aroundRadius: 'all',
                      },
                    },
                    {
                      indexName: 'Racqys',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<span>',
                        highlightPostTag: '</span>',
                        getRankingInfo: true,
                        aroundLatLngViaIP: true,
                        filters: 'status:active',
                        aroundRadius: 'all',
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    [gpsLocation, sport] // Include inputValue and setInputValue in the dependencies array
  );
  return (
    <div className="z-10">
      <input
        className="h-10 w-80 outline-none border-[1px] border-gray-200 rounded-md px-4 sm:mr-7 max-sm:mb-3 focus:ring-0 focus:outline-none focus:ring-offset-0 focus:border-gray-200"
        {...autocomplete.getInputProps({})}
        value={query}
        placeholder="Enter your location"
      />
      {!close && (
        <div
          style={{ position: 'absolute' }}
          {...autocomplete.getPanelProps({})}
        >
          {autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;

              return (
                <div key={`source-${index}`} className="w-80 sm:mt-2">
                  {items.length > 0 && (
                    <ul
                      className="bg-white max-wd-[350px]"
                      {...autocomplete.getListProps()}
                    >
                      {items.map((item) => (
                        <li
                          key={item.objectID}
                          className="aa-Item"
                          {...autocomplete.getItemProps({
                            item,
                            source,
                          })}
                          onClick={() => onItemClick(item)}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.path.includes('locations')
                                ? item._highlightResult?.name?.value ??
                                  item.name
                                : item?.name?.full,
                            }}
                          />
                          <p>
                            {item?.path.includes('locations')
                              ? item?.address
                              : item?.username}
                          </p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

const FindActivities = ({ location, navigate }) => {
  const [loading, setLoading] = useState(false);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  const [sportsFilter, setSportsFilter] = useState([]);
  const [page, setPage] = useState(0);
  const [activities, setActivities] = useState([]);
  const [closeAutoComplete, setCloseAutoComplete] = useState(false);
  const [autocompleteState, setAutocompleteState] = useState({});
  const [sport, setSport] = useState('');
  const [query, setQuery] = useState('');
  const [gpsLocation, setGpsLocation] = useState({});
  const [autoCompleteItem, setAutoCompleteItem] = useState({});

  const userLocation = GetLocation();

  const fetchRacqys = async () => {
    setLoading(true);
    try {
      if (Object.keys(userLocation.gpsLocation).length > 0) {
        const res = await client(
          racqys.fetchRacqys({
            page: page,
            sports:
              sportsFilter.length > 0
                ? [...sportsFilter]
                : [...SUPPORTED_SPORTS],
            userLocation:
              Object.keys(gpsLocation).length > 0
                ? { ...gpsLocation }
                : { ...userLocation.gpsLocation },
          })
        );

        if (res?.data?.racqys.length > 0) {
          setActivities([...res?.data?.racqys]);
        } else {
          setActivitiesLoading(true);
        }

        setTimeout(() => {
          setLoading(false);
          hideLoader();
        }, 1500);
      }
    } catch (error) {
      console.log('fetchRacqys', error);
      setTimeout(() => {
        setLoading(false);
        hideLoader();
      }, 1500);
    }
  };

  const handlePage = (type, pageNumber) => {
    if (type === 'increment') {
      setPage(page + 1);
    } else if (type === 'decrement' && page !== 0) {
      setPage(page - 1);
    } else if (type === 'specific-page') {
      setPage(pageNumber);
    }
  };

  useEffect(() => {
    fetchRacqys();
  }, [sportsFilter, userLocation.gpsLocation, gpsLocation, page]);

  useEffect(() => {
    if (sportsFilter.length > 0) {
      setSport(sportsFilter.join(','));
    }
  }, [sportsFilter]);

  const handleSportsSelectChange = (sports) => {
    let copySportsFilter = [];

    sports.map((sport) => {
      const isSelected = sportsFilter.includes(sport);

      if (isSelected) {
        copySportsFilter = [
          sportsFilter.filter((selectedSport) => selectedSport !== sport),
        ];
      } else {
        copySportsFilter = [...copySportsFilter, sport];
      }
    });

    setSportsFilter([...copySportsFilter]);
  };

  return (
    <Layout skipLoading={true} location={location}>
      <Helmet>
        <title>Explore Activities</title>
      </Helmet>
      <div className="w-full h-full min-h-[80vh]">
        {/* First Section */}
        <div className="bg-racqy-light-gray md:h-40 h-52 max-lg:pt-10 md:flex max-lg:flex-col mt-[60px] mx-auto lg:pl-[150px]">
          <div className="flex items-center max-lg:justify-center">
            <div className="w-12 h-12 bg-racqy-light-blue flex items-center justify-center rounded-md mr-5">
              <img src={locationBlue} />
            </div>
            <h2>Find activities</h2>
          </div>
          <div className="flex max-sm:flex-col items-center max-lg:justify-center max-lg:mt-5 md:ml-[100px]">
            <AutocompleteField
              autocompleteState={autocompleteState}
              setAutocompleteState={setAutocompleteState}
              sport={sport}
              query={query}
              close={closeAutoComplete}
              setQuery={(e) => {
                setQuery(e);
                setAutocompleteState({});
              }}
              onItemClick={(item) => {
                setAutoCompleteItem({ ...item });
                setQuery(item.name);
                setCloseAutoComplete(true);
                handleSportsSelectChange(item?.sports);
              }}
              setAutoCompleteDropDown={(value) => setCloseAutoComplete(value)}
            />
            <ActionButton
              title="Search"
              tint="blue"
              className="max-sm:w-80"
              onClick={() =>
                setGpsLocation({
                  latitude: autoCompleteItem?._geoloc?.lat,
                  longitude: autoCompleteItem?._geoloc?.lng,
                })
              }
            />
          </div>
        </div>
        {/* Second Section */}

        <div className="w-full h-full flex max-md:flex-col-reverse md:flex-row mt-5 px-5">
          {/* Left Side (Show all activities) */}

          {loading && (
            <div className="md:max-lg:w-[60%] lg:w-[70%] h-60 flex flex-col md:items-end items-center justify-center max-md:w-full md:max-lg:pr-40 lg:pr-48">
              <Spinner />
            </div>
          )}

          {!loading && (
            <div className="md:max-lg:w-[60%] lg:w-[70%] h-full flex flex-col items-end max-md:items-center max-md:justify-center max-md:w-full">
              {activities.length > 0
                ? activities.map((activity) => (
                    <ActivityCard
                      activity={activity}
                      key={activity?.id}
                      gpsLocation={userLocation.gpsLocation}
                      onClick={() => navigate(`/activity/${activity?.id}`)}
                    />
                  ))
                : activitiesLoading && (
                    <div className="flex flex-col items-center justify-center md:max-lg:mr-16 lg:mr-36 my-7">
                      <img src={racqyIcon} />
                      <h6 className="text-lg mt-3 text-racqy-blue">
                        No activities were found nearby
                      </h6>
                    </div>
                  )}
              <div className="flex flex-col items-center justify-center w-96 mt-7 md:max-lg:mr-10 lg:mr-24 max-sm:w-[350px]">
                {/* Pagination buttons */}
                <div className="flex justify-evenly items-center w-full">
                  <h6 className="font-sans text-racqy-text-light">Page:</h6>
                  <div className="h-9 border-[1px] border-gray-200 flex items-center">
                    {page === 0 ? (
                      <div className="w-16 h-full flex items-center justify-center border-r-[1px] border-gray-200 cursor-not-allowed bg-gray-100 opacity-50">
                        <img
                          src={arrowIcon}
                          className="w-4 h-4 rotate-180 mr-1"
                        />
                        <h6>Prev</h6>
                      </div>
                    ) : (
                      <div
                        className="w-16 h-full flex items-center justify-center border-r-[1px] border-gray-200 cursor-pointer"
                        onClick={() => handlePage('decrement')}
                      >
                        <img
                          src={arrowIcon}
                          className="w-4 h-4 rotate-180 mr-1"
                        />
                        <h6>Prev</h6>
                      </div>
                    )}
                    {activities.length === 10 ? (
                      <div
                        className="w-16 h-full flex items-center justify-center cursor-pointer"
                        onClick={() => handlePage('increment')}
                      >
                        <h6>Next</h6>
                        <img src={arrowIcon} className="w-4 h-4 ml-1" />
                      </div>
                    ) : (
                      <div className="w-16 h-full flex items-center justify-center cursor-not-allowed bg-gray-100 opacity-50">
                        <h6>Next</h6>
                        <img src={arrowIcon} className="w-4 h-4 ml-1" />
                      </div>
                    )}
                  </div>
                  <h6 className="font-sans text-racqy-text-light">
                    {`${activities.length} ${
                      activities.length === 1 ? 'result' : 'results'
                    }`}
                  </h6>
                </div>
                {/* Total results */}
                {/* <div className="mt-5">
                  <h6 className="font-sans text-racqy-text-light">
                    Showing 1-20 of 63
                  </h6>
                </div> */}
              </div>
            </div>
          )}

          {/* Right Side (Categories selection section) */}
          <div className="lg:w-[30%] md:max-lg:w-[40%] h-full lg:pl-4 max-md:hidden">
            <CategoriesBox
              sportsFilter={sportsFilter}
              setSportsFilter={setSportsFilter}
            />
          </div>
          {/* Mobile Categories Box */}
          <div className="max-md:flex hidden w-full max-md:items-center max-md:justify-center my-5">
            <MobileCategoriesBox
              placeHolder="Categories showing"
              sportsFilter={sportsFilter}
              setSportsFilter={setSportsFilter}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FindActivities;
