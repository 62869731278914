import React from 'react';
import { getLevelColor } from '../../helper/racqyHelper';

const NumberCircle = ({ number, size }) => {
  const numArray = [];

  if (number.length > 1) {
    for (let i = number[0]; i <= number[1]; i++) {
      numArray.push(i);
    }
  } else {
    return <h6>No difficulty selected</h6>;
  }

  return (
    <>
      {numArray.map((item) => {
        let level = getLevelColor(item);

        return (
          <div
            style={{
              backgroundColor: level.bg,
              width: size,
              height: size,
              fontSize: size * 0.5,
            }}
            className="flex items-center justify-center rounded-full mr-2 font-bold"
          >
            <h6>{item}</h6>
          </div>
        );
      })}
    </>
  );
};

export default NumberCircle;
