import React from 'react';
import Select from 'react-select';
import { SUPPORTED_SPORTS, formatSportsName } from '../../utils/racqy';

const MobileCategoriesBox = ({
  placeHolder,
  sportsFilter,
  setSportsFilter,
}) => {
  const options = SUPPORTED_SPORTS.map((sport) => ({
    value: sport,
    label: formatSportsName(sport),
  }));

  const handleSportsSelectChange = (sports) => {
    sports.map((sport) => {
      const isSelected = sportsFilter.includes(sport.value);

      if (isSelected) {
        setSportsFilter(
          sportsFilter.filter((selectedSport) => selectedSport !== sport.value)
        );
      } else {
        setSportsFilter([...sportsFilter, sport.value]);
      }
    });
  };

  return (
    <div className="w-96 max-sm:w-[350px] cursor-pointer">
      <Select
        placeholder={placeHolder}
        className="focus:ring-0 focus:outline-none focus:ring-offset-0"
        isMulti
        value={options.filter((option) => sportsFilter.includes(option.value))}
        options={options}
        isClearable
        isSearchable
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={(value) => handleSportsSelectChange(value)}
      />
    </div>
  );
};

export default MobileCategoriesBox;
