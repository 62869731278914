import React, { useState } from 'react';
import './index.css';
import filterIcon from '../../../static/icons/filter.svg';
import {
  SUPPORTED_SPORTS,
  formatSportsName,
  getSportIcon,
} from '../../utils/racqy';

const CategoriesBox = ({ sportsFilter, setSportsFilter }) => {
  const [showMore, setShowMore] = useState(5);

  const loadMoreData = () => {
    setShowMore(showMore + (SUPPORTED_SPORTS.length - showMore));
  };

  const loadLessData = () => {
    setShowMore(5);
  };

  const handleSportsCheckboxChange = (sport) => {
    const isSelected = sportsFilter.includes(sport);

    if (isSelected) {
      setSportsFilter(
        sportsFilter.filter((selectedSport) => selectedSport !== sport)
      );
    } else {
      setSportsFilter([...sportsFilter, sport]);
    }
  };

  return (
    <div className="lg:w-72 rounded-xl shadow-gray-100 shadow-lg pr-5 pl-3 py-3 flex-grow self-center md:max-lg:mr-5">
      <div className="flex justify-between items-center pr-1 pl-2">
        <h6 className="font-sans">Categories showing</h6>
        <img src={filterIcon} className="w-4 h-4" />
      </div>
      {/* Render Sports */}
      {SUPPORTED_SPORTS.slice(0, showMore).map((sport) => {
        let isChecked = sportsFilter.find((item) => item === sport);
        return (
          <div className="flex items-center justify-between mt-3">
            <div className="flex items-center justify-between">
              <img
                src={getSportIcon(sport)}
                className={`w-5 h-5 mr-5 sport-icon-svg ${
                  sport === 'basketball' && 'w-4 h-4 ml-[3px]'
                }`}
              />
              <label className="text-sm" htmlFor={sport}>
                {formatSportsName(sport)}
              </label>
            </div>
            <input
              type="checkbox"
              className="rounded-full border-gray-300 border-2 text-green-500 w-6 h-6 focus:ring-0 focus:outline-none focus:ring-offset-0"
              id={sport}
              checked={isChecked ? true : false}
              onClick={() => handleSportsCheckboxChange(sport)}
            />
          </div>
        );
      })}
      {showMore === SUPPORTED_SPORTS.length ? (
        <h6
          onClick={loadLessData}
          className="font-bold text-racqy-text-light mt-3 cursor-pointer ml-1"
        >
          Show less
        </h6>
      ) : (
        <h6
          onClick={loadMoreData}
          className="font-bold text-racqy-text-light mt-3 cursor-pointer ml-1"
        >
          Show more
        </h6>
      )}
    </div>
  );
};

export default CategoriesBox;
