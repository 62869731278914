import React from 'react';
import './index.css';
import { getSportIcon } from '../../utils/racqy';
import locationIcon from '../../assets/icons/location.svg';
import calenderIcon from '../../assets/icons/calendar.svg';
import NumberCircle from '../NumberCircle';
import { getSportHeaderImage } from '../../helper/racqyHelper';
import moment from 'moment';
import infinitySvg from '../../assets/Infinity.svg';

const ActivityCard = ({ key, activity, onClick }) => {
  const availableSpot =
    activity?.maxPlayerCount !== -1 &&
    activity?.maxPlayerCount - activity?.players?.length;

  let back_img = getSportHeaderImage(activity?.sport);

  const formattedTimeHandler = () => {
    let dateString = '';
    const momentRacqyDate = moment(activity?.date);

    let formattedStartTime =
      activity?.startTime?.min === 0
        ? `${activity?.startTime?.hour}:${activity?.startTime?.min}0`
        : `${activity?.startTime?.hour}:${activity?.startTime?.min}`;
    let formattedEndTime =
      activity?.endTime?.min === 0
        ? `${activity?.endTime?.hour}:${activity?.endTime?.min}0`
        : `${activity?.endTime?.hour}:${activity?.endTime?.min}`;

    const diff = momentRacqyDate
      .startOf('day')
      .diff(moment().startOf('day'), 'days');

    if (diff >= 7) {
      return (dateString = `${momentRacqyDate.format(
        'MMM d'
      )}, ${formattedStartTime} - ${formattedEndTime}`);
    } else if (diff >= 2) {
      return (dateString = `${momentRacqyDate.format(
        'ddd d MMM'
      )}, ${formattedStartTime} - ${formattedEndTime}`);
    } else if (diff === 1) {
      return (dateString = `Tomorrow, ${formattedStartTime} - ${formattedEndTime}`);
    } else {
      return (dateString = `Today, ${formattedStartTime} - ${formattedEndTime}`);
    }
  };

  const availableSpotHandler = () => {
    if (availableSpot === 2 && activity?.maxPlayerCount > 2) {
      return `${availableSpot} spots left`;
    } else if (availableSpot === 1) {
      return `${availableSpot} spot left`;
    } else {
      return `${
        activity?.sortDistance || activity?.location?.distance
      } km away`;
    }
  };

  const playersHandler = () => {
    if (activity?.maxPlayerCount <= 4 && activity?.maxPlayerCount > 0) {
      return (
        <div className="w-[35%] flex justify-between px-4 flex-wrap">
          {activity?.players.map((player) => (
            <img
              src={player?.profilePictureStorageUrl}
              className="w-10 h-10 rounded-full"
            />
          ))}
          {[...Array(availableSpot)].map((_) => (
            <div className="w-10 h-10 rounded-full bg-racqy-light-blue border-dashed border-2 border-racqy-blue" />
          ))}
        </div>
      );
    } else if (activity?.maxPlayerCount > 4) {
      return (
        <div className="w-[40%] flex items-center justify-end pr-4">
          {activity?.players.slice(0, 4).map((player, index) => (
            <img
              src={player?.profilePictureStorageUrl}
              className={`w-7 h-7 mr-[-8px] rounded-full ${
                index === 0 && 'z-[${index}]'
              }`}
            />
          ))}
          {activity?.players.length <= 4 &&
            [...Array(4 - activity?.players.length)].map((_) => (
              <div className="w-7 h-7 mr-[-8px] rounded-full bg-racqy-light-blue border-dashed border-2 border-racqy-blue" />
            ))}
          {activity?.maxPlayerCount - 4 > 0 ? (
            <h6 className="ml-2.5">+{activity?.maxPlayerCount - 4}</h6>
          ) : null}
        </div>
      );
    } else if (activity?.maxPlayerCount === -1) {
      return (
        <div className="w-[40%] flex items-center justify-end pr-4">
          {activity?.players.slice(0, 4).map((player, index) => (
            <img
              src={player?.profilePictureStorageUrl}
              className={`w-7 h-7 mr-[-8px] rounded-full ${
                index === 0 && 'z-[${index}]'
              }`}
            />
          ))}
          {activity?.players.length <= 4 &&
            [...Array(4 - activity?.players.length)].map((_) => (
              <div className="w-7 h-7 mr-[-8px] rounded-full bg-racqy-light-blue border-dashed border-2 border-racqy-blue" />
            ))}

          <img src={infinitySvg} className="ml-2.5 w-5 h-5 infinity-svg" />
        </div>
      );
    }
  };

  return (
    <div
      className="relative overflow-hidden h-60 w-96 border-[1px] border-gray-200 rounded-[25px] mt-7 md:max-lg:mr-10 lg:mr-24 max-sm:w-[350px] cursor-pointer"
      key={key}
      onClick={onClick}
    >
      {/* Section with background image */}
      <div
        className="bg-no-repeat absolute top-0 w-full h-[110px]"
        style={{
          backgroundImage: activity?.customImage
            ? `url(${activity?.customImage})`
            : `url(${back_img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Sport icon and how many spots left or distance */}
        <div className="flex items-center justify-between py-[15px] px-[23px]">
          {activity?.sport === 'basketball' ? (
            <svg
              id="Group_3028"
              data-name="Group 3028"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 133.311 133.311"
            >
              <path
                id="primary"
                d="M32.241,101.521a3.322,3.322,0,0,1-2.645-1.3A78.125,78.125,0,0,1,13.5,52.246,78.125,78.125,0,0,1,29.6,4.275a3.328,3.328,0,1,1,5.284,4.047,71.469,71.469,0,0,0-14.724,43.9q0,.021,0,.042a71.469,71.469,0,0,0,14.724,43.9,3.328,3.328,0,0,1-2.64,5.351Z"
                transform="translate(77.972 14.41)"
                fill="#ffffff"
              />
              <path
                id="primary-2"
                data-name="primary"
                d="M6.635,101.521A3.328,3.328,0,0,1,4,96.17,71.316,71.316,0,0,0,15.012,75.444,71.316,71.316,0,0,0,18.72,52.267q0-.021,0-.042a71.316,71.316,0,0,0-3.708-23.177A71.316,71.316,0,0,0,4,8.321,3.328,3.328,0,1,1,9.28,4.275,77.957,77.957,0,0,1,21.322,26.931a77.954,77.954,0,0,1,4.053,25.315A77.954,77.954,0,0,1,21.322,77.56,77.958,77.958,0,0,1,9.28,100.217,3.323,3.323,0,0,1,6.635,101.521Z"
                transform="translate(16.462 14.41)"
                fill="#ffffff"
              />
              <path
                id="primary-3"
                data-name="primary"
                d="M67.156,133.811h0A66.672,66.672,0,0,1,41.209,5.739,66.241,66.241,0,0,1,67.156.5h0A66.672,66.672,0,0,1,93.1,128.572a66.241,66.241,0,0,1-25.946,5.239ZM7.246,70.483a59.945,59.945,0,0,0,56.582,56.582V70.483Zm63.238,0v56.582a59.945,59.945,0,0,0,56.582-56.582H70.483Zm0-6.656h56.582A59.945,59.945,0,0,0,70.483,7.246Zm-63.238,0H63.828V7.246A59.945,59.945,0,0,0,7.246,63.828Z"
                transform="translate(-0.5 -0.5)"
                fill="#ffffff"
              />
            </svg>
          ) : activity?.sport === 'custom' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 76.511 76.5"
            >
              <path
                id="Path_4377"
                data-name="Path 4377"
                d="M50.005,28.585l3.91,3.91L15.41,71H11.5V67.09l38.505-38.5M65.3,3A4.254,4.254,0,0,0,62.33,4.233L54.552,12.01,70.49,27.948l7.778-7.778a4.232,4.232,0,0,0,0-5.993L68.322,4.233A4.174,4.174,0,0,0,65.3,3ZM50,16.558l-47,47V79.5H18.937l47-47L50,16.557Z"
                transform="translate(-3 -3)"
                fill="#fff"
              />
            </svg>
          ) : (
            <img
              src={getSportIcon(activity?.sport)}
              className="w-6 h-6 activity-card-svg fill-red"
            />
          )}
          <h6 className="text-xs text-white font-light">
            {availableSpotHandler()}
          </h6>
        </div>
        {/* Sport Name */}
        <div className="flex items-center justify-between py-[15px] px-[23px]">
          <h6 className="text-white">{activity?.activityTitle}</h6>
        </div>
      </div>
      {/* Details Section */}
      <div className="w-full h-[130px] absolute bottom-0 flex pt-5 pl-5">
        <div
          className={`${activity?.maxPlayerCount <= 4 ? 'w-[65%]' : 'w-[60%]'}`}
        >
          <div className="flex items-center">
            <div className="h-7 w-7 bg-racqy-settings-panel flex justify-center items-center rounded-md mr-3">
              <img src={locationIcon} className="h-5 w-5" />
            </div>
            <h6 className="truncate">
              {activity?.location?.name.length > 14
                ? activity?.location?.name.slice(0, 21) + '...'
                : activity?.location?.name}
            </h6>
          </div>
          <div className="flex items-center mt-2">
            <div className="h-7 w-7 bg-racqy-settings-panel flex justify-center items-center rounded-md mr-3">
              <img src={calenderIcon} className="h-5 w-5" />
            </div>
            <h6>{formattedTimeHandler()}</h6>
          </div>
          <div className="flex items-center mt-2">
            <NumberCircle number={activity?.difficulty} size={22} />
          </div>
        </div>
        {/* Show joined players */}
        {playersHandler()}
      </div>
    </div>
  );
};

export default ActivityCard;
